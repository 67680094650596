export const setInsumosASolicitar = (state, payload) => {
  state.insumosASolicitar = payload;
};

export const setFondosAsignados = (state, payload) => {
  state.fondosAsignados = payload;
};

export const setSolicitudesCompra = (state, payload) => {
  state.solicitudesCompraList = payload;
};

export const setPaginationData = (state, payload) => {
  state.filters.page = Number(payload.page);
  state.filters.per_page = Number(payload.per_page);
  state.filters.total_rows = Number(payload.total_rows);
};

export const setPage = (state, payload) => {
  state.filters.page = payload;
};

export const setPerPage = (state, payload) => {
  state.filters.per_page = payload;
};

export const setTipoCompraFilter = (state, payload) => {
  state.filters.id_tipo_solicitud = payload;
  state.filters.page = 1;
};

export const setEstadoCompraFilter = (state, payload) => {
  state.filters.id_estado = payload;
  state.filters.page = 1;
};

export const setCorrelativoFilter = (state, payload) => {
  state.filters.searchByNumber = payload;
  state.filters.page = 1;
};

export const setFechasFilter = (state, payload) => {
  state.filters.fecha_desde = payload.fecha_desde;
  state.filters.fecha_hasta = payload.fecha_hasta;
  state.filters.page = 1;
};

export const setUnidadesFilter = (state, payload) => {
  state.filters.id_unidad_filtro = payload;
  state.filters.page = 1;
};
export const setPasoSolicitud = (state, payload) => {
  state.pasoSolicitud = payload;
};
export const setCorrelativoSolicitud = (state, payload) => {
  state.correlativoSolicitud = payload;
};

export const setListaAdministradoresContrato = (state, payload) => {
  state.lista_administradores_de_contrato = payload;
};

export const setBitacora = (state, payload) => {
  state.bitacora = payload;
};
