<template>
    <Fragment>
        <div v-if="!readOnly" class="d-flex justify-center align-center flex-wrap flex-md-nowrap mb-4" style="gap: 16px;">
            <v-text-field 
                v-model="filename"
                label="Nombre" 
                placeholder="Nombre" 
                outlined 
                hide-details
                :disabled="fileSaveLoading"
                style="width: 100%"
                class="flex-grow-1 flex-shrink-1"
            />
            <v-file-input
                v-model="file"
                label="Archivo"
                placeholder="Seleccione un archivo"
                outlined
                light
                accept="application/pdf"
                hide-details
                :disabled="fileSaveLoading"
                style="width: 100%"
                class="flex-grow-1 flex-shrink-1"
            />
            <v-btn 
                color="secondary" 
                style="width: 100%" 
                class="flex-grow-1 flex-shrink-1"
                :loading="fileSaveLoading"
                @click.stop="confirmFileSave"
                x-large
            >
                Guardar
            </v-btn>
            <v-btn
                color="primary" 
                style="width: 100%" 
                class="flex-grow-1 flex-shrink-1"
                :loading="informacionOrdenCompra.isLoading || orden.isLoading"
                @click.stop="cargarInformacionOrdenCompra"
                x-large
            >
            Orden de compra
       </v-btn>
        </div>
        <DataTableComponent 
            no-gutters 
            dense 
            class="mb-8" 
            :items="logs.data" 
            :headers="mergedHeaders"
            :show_loading="logs.isLoading"
            :tiene_paginacion="false"
            :total_registros="recordsTotal"
        >
            <template v-slot:item.acciones="{ item }">
                <div class="d-flex justify-center align-center" style="gap: 16px;">
                    <v-btn 
                        icon 
                        @click.stop="handleFilePreview(item)" 
                        :loading="filePreview.isLoading && item.id === activeItem?.id"
                        :disabled="filePreview.isLoading"
                    >
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>

                    <v-btn 
                        v-if="!readOnly"
                        icon 
                        @click.stop="confirmFileDelete(item)" 
                        :loading="fileDeletion.isLoading && item.id === activeItem?.id"
                        :disabled="fileDeletion.isLoading"
                    >
                        <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-for="headerName in headersNames" v-slot:[`item.${headerName}`]="{ item }">
                <slot :name="`item.${headerName}`" :item="item">
                    {{ item[headerName] }}
                </slot>
            </template>
            <template v-slot:pagination>
                <PaginationComponent class="mt-4" :total="recordsTotal" :pagination="pagination" />
            </template>
        </DataTableComponent>
        <ConfirmationModalComponent 
            description="¿Desea guardar el archivo?"
            :is-open="isSaveConfirmOpen"
            :is-loading="fileSave.isLoading"
            @confirm="handleFileSave"       
            @cancel="isSaveConfirmOpen = false"     
        />
        <ConfirmationModalComponent 
            description="¿Desea eliminar el archivo?"
            :is-open="isDeleteConfirmOpen"
            :is-loading="fileSave.isLoading"
            @confirm="handleFileDelete"       
            @cancel="isDeleteConfirmOpen = false"     
        />
        <PdfModal 
            :isOpen="isPreviewModalOpen" 
            :source-loadable="filePreview" 
            @on-visibility-change="handlePreviewVisibilityChange"
            :filename="previewFilename"
        />
        <ModalOrdenCompraPreview 
            :is-open="modalGenerarOrdenCompra"
            @on-visibility-change="cerrarModalGenerarOrdenCompra"
            @on-save="confirmarGeneracionOrden"
            :solicitud="informacionOrdenCompra.data?.solicitud"
            :contactoUCP="informacionOrdenCompra.data?.contactoUCP"
            :total="informacionOrdenCompra.data?.total"
            :proveedor="informacionOrdenCompra.data?.proveedor"
            :productos="informacionOrdenCompra.data?.productos ?? []"
            :orden-compra="informacionOrdenCompra.data?.orden_compra"
            :valores-previos="informacionOrdenCompra.data?.json_previo"
        />
        <ConfirmationModalComponent 
            description="¿Desea generar la orden de compra?"
            :is-open="confirmacionGeneracionOrdenCompra"
            :is-loading="orden.isLoading"
            @confirm="generarOrdenCompra"       
            @cancel="confirmacionGeneracionOrdenCompra = false"     
        />
    </Fragment>
</template>
<script>
import ModalOrdenCompraPreview from '@/views/CMProcesoAprobacionCompraElectronica/components/ModalOrdenCompraPreview.vue';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent, PaginationComponent } from '@/components/utils';
import DataTableComponent from "@/components/DataTableComponent.vue";
import { PaginationHandler } from '@/utils/pagination-handler.js';
import { convertToFormData, getExtFromPath, removePropsFromObjectIfNil } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { Fragment } from 'vue-fragment';

export default {
    name: 'AttachmentLogs',
    components: { 
        DataTableComponent, 
        Fragment, 
        ConfirmationModalComponent, 
        PdfModal, 
        PaginationComponent,
        ModalOrdenCompraPreview,
    },
    emits: ['on-preview', 'on-delete', 'on-save', 'on-paginate'],
    props: {
        logs: { type: Object, required: true },
        filePreview: { type: Object, required: true },
        fileSave: { type: Object, required: true },
        idProveedor: { type: Number },
        fileDeletion: { type: Object, required: true },
        headers: { type: Array, required: true },
        nameColumn: { type: String, default: 'nombre_documento' },
        urlColumn: { type: String, default: 'ruta_documento' },
        readOnly: { type: Boolean },
    },
    data: () => ({
        filename: '',
        file: null,
        isSaveConfirmOpen: false,
        isDeleteConfirmOpen: false,
        isPreviewModalOpen: false,
        activeItem: null,
        requestId: null,
        orden: createLoadable([]),
        // Paginacion
        pagination: new PaginationHandler(),
        // generacion orden compra
        modalGenerarOrdenCompra: false,
        parametrosOrdenCompra: null,
        confirmacionGeneracionOrdenCompra: false,
        // Informacion orden de compra
        informacionOrdenCompra: createLoadable(null),
    }),
    computed: {
        recordsTotal() {
            return this.logs.data.length;
        },
        mergedHeaders() {
            return this.headers.concat({
                align: 'center', 
                sortable: false, 
                text: 'Acciones', 
                value: 'acciones',
            });
        },
        headersNames() {
            return this.headers.map((header) => header.value);
        },
        fileSaveLoading() {
            return this.fileSave.isLoading;
        },
        previewFilename() {
            if (!this.activeItem) return '';

            const ext = getExtFromPath(this.activeItem[this.urlColumn]);
            return `${this.activeItem[this.nameColumn]}.${ext}`;
        },
    },
    methods: {
        confirmFileSave() {
            if (!this.filename) {
                return this.pushAppMessage({ message: "Ingrese un nombre de archivo válido", type: "error" });
            }

            if (!this.file) {
                return this.pushAppMessage({ message: "Seleccione un archivo para guardar.", type: "error" });
            }

            this.isSaveConfirmOpen = true;
        },
        confirmFileDelete(item) {
            this.activeItem = item;
            this.isDeleteConfirmOpen = true;
        },
        handleFileSave() {
            this.isSaveConfirmOpen = false;
            this.$emit('on-save', { name: this.filename, file: this.file });
        },
        handleFileDelete() {
            this.$emit('on-delete', { record: this.activeItem });
            this.isDeleteConfirmOpen = false;
        },
        confirmarGeneracionOrden(parametros) {
            this.parametrosOrdenCompra = parametros;
            this.confirmacionGeneracionOrdenCompra = true;
        },
        async generarOrdenCompra() {
            toggleLoadable(this.orden);

            const parametros = {
                ...this.parametrosOrdenCompra,
                id_proveedor: this.idProveedor,
            };
            
            const keysParametros = Object.keys(parametros);

            const formData = convertToFormData(removePropsFromObjectIfNil(parametros, keysParametros));

            const { data, headers } = await this.services.PurchaseRequest.generarOrdenCompra(this.requestId, formData);
            this.confirmacionGeneracionOrdenCompra = false;
            setLoadableResponse(this.orden, data, { isFile: true });  
            
            if (data && !('error' in data)) {
                const filename = `orden_compra.pdf`;
                const blob = new Blob([data], { type: headers["content-type"] });
                
                const file = new File([blob], filename, { type: headers["content-type"] });
    
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = file.name;
                link.click();
            }
        },
        async cargarInformacionOrdenCompra() {
            toggleLoadable(this.informacionOrdenCompra);
            const { data } = await this.services.PurchaseRequest.cargarInformacionOrdenCompra(this.requestId, this.idProveedor);
            setLoadableResponse(this.informacionOrdenCompra, data);

            if (isResponseSuccesful(data)) {
                this.modalGenerarOrdenCompra = true;
            }
        },
        handleFilePreview(item) {
            this.activeItem = item;
            this.isPreviewModalOpen = true;
            this.$emit('on-preview', item);
        },
        handlePreviewVisibilityChange(visible) {
            this.isPreviewModalOpen = visible;
        },
        // Orden compra
        cerrarModalGenerarOrdenCompra(visible) {
            this.modalGenerarOrdenCompra = visible;
        },
    },
    watch: {
        fileSaveLoading(value) {
            if (value) return;

            if (this.filename) this.filename = '';
            if (this.file) this.file = null;
        },
    },
    created() {
        this.requestId = this.$route.params.id;
    },
}
</script>