<template>
    <v-dialog v-model="internalOpen" max-width="940px" persistent>
        <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
            <div class="d-flex align-center justify-space-between py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <div class="d-flex align-center" style="gap: 16px;">
                    <v-switch
                        v-model="modoVistaPrevia"
                        label="Vista previa"
                        class="mt-0 mb-0"
                        color="secondary"
                        hide-details
                    />
                    <v-btn 
                        color="primary"
                        @click.stop="generarOrdenCompra"
                        :disabled="productos.length === 0"
                        x-large
                    >
                        Generar
                    </v-btn>
                </div>
            </div>
            <div class="px-1 mt-1">
                <v-alert v-if="productos.length === 0" dense type="info" class="px-2 py-2 mb-4">
                    No hay productos para generar la orden de compra
                </v-alert>

                <div class="top-scroller" @scroll="manejarCambioEnScroll">
                    <div style="width: 871px; padding: 0 38px; box-sizing: border-box; border: 1px solid transparent;"></div>
                </div>
                <div class="plantilla mx-auto" ref="plantilla" style="width: 871px;">
                    <div style="width: 871px; min-height: 1123px; border: 1px solid black; padding: 38px; box-sizing: border-box;">
                        <div>
                            <!-- Header -->
                            <div class="d-flex justify-space-between">
                                <div>
                                    <h1 class="text-uppercase text-bold mt-4" style="font-size: 22px; line-height: 26px;">
                                        Orden de compra
                                    </h1>
                                    <p class="font-size-body text-bold mb-0 mt-1">No. {{ ordenCompra?.codigo }}</p>
                                    <div class="d-flex align-center mt-1 text-h6">
                                        <span class="font-size-body text-bold">Código institucional:</span>
                                        <span v-if="modoVistaPrevia" class="font-size-body text-bold mb-0 ml-2">{{ form.correlativo_orden_institucion }}</span>
                                        <v-text-field 
                                            v-else
                                            v-model="form.correlativo_orden_institucion"
                                            v-bind="generarCommonProps(correlativoOrdenInstitucion)"
                                            placeholder="Código institucional"
                                            no-resize
                                            dense
                                            @blur="$v.form.correlativo_orden_institucion.$touch()"
                                            @change="$v.form.correlativo_orden_institucion.$touch()"
                                            class="ml-2"
                                        />
                                    </div>
                                </div>
                                <img 
                                    class="max-width-full" 
                                    style="width: 300px; object-fit: contain;"
                                    src="@/assets/img/comprasal_login_v2.png"
                                />
                            </div>
                            <!-- Main -->
                            <div class="mt-8">
                                <div class="d-flex" style="gap: 16px;">
                                    <div style="width: 50%;">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="px-2 py-2">Dirección de facturación</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-center" style="height: 90px;">
                                                        <span v-if="modoVistaPrevia">{{ institucion }}, {{ form.direccion_facturacion }}</span>
                                                        <v-textarea 
                                                            v-else
                                                            v-model="form.direccion_facturacion"
                                                            v-bind="generarCommonProps(direccion_facturacionErrors)"
                                                            class="my-2 mx-2"
                                                            rows="3"
                                                            no-resize
                                                            @blur="$v.form.direccion_facturacion.$touch()"
                                                            @change="$v.form.direccion_facturacion.$touch()"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
    
                                        <table class="table mt-4">
                                            <thead>
                                                <tr>
                                                    <th class="px-2 py-2 text-left">Detalles de institución solicitante</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-center" style="height: 90px;">
                                                        <span v-if="solicitud?.id_tipo_solicitud != 2"> {{ `Dirección: ${solicitud?.direccion}` }}
                                                        </span>
                                                        <br>
                                                        {{ `Nombre Jefe UCP: ${contactoUCP?.nombre_ucp}` }}
                                                        <br>
                                                        {{ `Correo Jefe UCP: ${contactoUCP?.email_ucp}` }}
                                                        <br>
                                                        {{ `Teléfono Jefe UCP: ${(contactoUCP?.telefono_ucp.telefono)}` }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
    
                                    <div style="width: 50%; border: solid 1px #000000;">
                                        <table class="table" style="border: unset">
                                            <thead>
                                                <tr>
                                                    <th class="px-2 py-2" style="width:32%;">Información</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="!esCMCombustible">
                                                    <td class="px-2 py-2">Fecha estimada de entrega:</td>
                                                    <td class="px-2 py-2 text-right">
                                                        <TiempoEntrega
                                                            :fecha="productos[0]?.fechaEntrega" 
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="px-2 py-2">Fecha de emisión:</td>
                                                    <td class="px-2 py-2 text-right">
                                                        {{ moment().format('DD/MM/YYYY') }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="px-2 py-2">Moneda:</td>
                                                    <td class="px-2 py-2 text-right">USD</td>
                                                </tr>
                                                <tr>
                                                    <td class="px-2 py-2" style="vertical-align: top;">Proveedor:</td>
                                                    <td class="px-2 py-2 text-right" style="word-break: break-all;">
                                                        {{  `${informacionProveedor.nombre} - (${informacionProveedor.nombre_comercial})`  }} 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="px-2 py-2" style="vertical-align: top;">Numero de contacto proveedor:</td>
                                                    <td class="px-2 py-2 text-right" style="word-break: break-all;">
                                                        {{ informacionProveedor.telefono }} 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="px-2 py-2">NIT proveedor:</td>
                                                    <td class="px-2 py-2 text-right" style="word-break: break-all;">
                                                        {{ informacionProveedor.numero_documento }} 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="px-2 py-2">Correo electrónico proveedor:</td>
                                                    <td class="px-2 py-2 text-right" style="word-break: break-all;">
                                                        {{ informacionProveedor.email}}
                                                    </td>                                                
                                                </tr>
                                                <tr>
                                                    <td class="px-2 py-2">Dirección:</td>
                                                    <td class="px-2 py-2 text-right" style="word-break: break-all;">
                                                        {{ informacionProveedor.direccion }} 
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
    
                                <table class="simple-table bordered-table mt-4">
                                    <thead>
                                        <tr>
                                            <th class="text-center font-size-body" style="width: 5%;">#</th>
                                            <th class="text-center font-size-body" style="width: 10%;">SKU</th>
                                            <th class="py-1 px-0 text-center font-size-body" style="width: 20%;">Artículo</th>
                                            <th class="text-center font-size-body" style="width: 30%;">Descripción</th>
                                            <th class="text-center font-size-body" style="width: 5%;">Cant.</th>
                                            <th class="text-center font-size-body" style="width: 10%;">Detalles</th>
                                            <th class="text-center font-size-body py-1" style="width: 10%;">Precio <br /> unitario</th>
                                            <!-- <th class="text-center font-size-body" style="width: 10%;">Fecha de <br /> entrega</th> -->
                                            <th class="text-center font-size-body" style="width: 10%;">Total (IVA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(producto, index) in productos">
                                            <td class="text-center font-size-body text-bold">{{ index + 1 }}</td>
                                            <td class="text-center font-size-body" style="word-wrap: break-word">{{ producto.sku }}</td>
                                            <td class="px-2 py-1 text-center font-size-body text-uppercase">{{ producto.nombre }}</td>
                                            <td class="px-2 py-4 text-center font-size-body text-uppercase" style="overflow-wrap: break-word">{{ producto.descripcion }}</td>
                                            <td class="text-center font-size-body">{{ producto.cantidad }}</td>
                                            <td class="text-center font-size-body text-uppercase">
                                                <div v-if="producto.unidadMedida" class="mb-2 mt-2">
                                                    U/M: {{ producto.unidadMedida }}
                                                </div>
                                                <div v-if="producto.altura">
                                                    <v-divider color="gray"/> 
                                                    <br />
                                                    Altura: {{ producto.altura }}
                                                </div>
                                                <div v-if="producto.altura" class="mb-2 mt-2">
                                                    <v-divider color="gray"/> 
                                                    <br />
                                                    Base: {{ producto.base }}
                                                </div>
                                                <div v-if="producto.monto" class="mb-2 mt-2">
                                                    <v-divider color="gray"/> 
                                                    <br />
                                                    Monto: {{ producto.monto }}
                                                </div>
                                                <div v-if="producto.lineas" class="mb-2 mt-2">
                                                    <v-divider color="gray"/> 
                                                    <br />
                                                    Lineas: {{ producto.lineas }}
                                                </div>
                                                <div v-if="producto.vigencia" class="mb-2 mt-2">
                                                    <v-divider color="gray"/> 
                                                    <br />
                                                    {{ `Vigencia: ${producto.vigencia} meses`  }}
                                                </div>
                                                <div v-if="producto.cifra" class="mb-2 mt-2">
                                                    <v-divider color="gray"/> 
                                                    <br />
                                                    {{ (producto.subGrupo.monto || producto.subGrupo.cifra) && producto.subGrupo.vigencia ? `Cantidad tarjetas: ${producto.cifra}` : `Cifra: ${producto.cifra}` }}
                                                </div>
                                                <div v-if="producto.gastos_administrativos" class="mb-2 mt-2">
                                                    <v-divider color="gray"/> 
                                                    <br />
                                                    {{ (producto.subGrupo.monto || producto.subGrupo.cifra) && producto.subGrupo.vigencia ? `${producto.tipoGasto.nombre}: $${producto.gastos_administrativos}` : `-` }}
                                                    <br />
                                                    <b style="font-size: 10px;"> {{ `($${producto.tipoGasto.precio} PRECIO UNITARIO)` }}</b>
                                                </div>
                                            </td>
                                            <td class="text-center font-size-body">
                                                <b v-if="producto.subGrupo.es_combustible">-</b>
                                                <template v-else>
                                                    ${{ producto.precio }}
                                                    <br />
                                                    <b style="font-size: 10px;">{{ producto.precio_con_iva ? `(${(Number(producto.precio / 1.13).toFixed(5))} SIN IVA)` : '' }}</b>
                                                </template>
                                            </td>
                                            <td class="text-center font-size-body">${{ producto.subtotal.toFixed(2) }}
                                                <br />
                                            <b style="font-size: 10px;">{{ producto.precio_con_iva ? `(${(Number(producto.subtotal / 1.13).toFixed(2))} SIN IVA)` : '' }}</b>
                                        </td>
                                        </tr>

                                        <tr>
                                            <td colspan="9">
                                                <div style="height: 75px;" />
                                            </td>
                                        </tr>

                                        <tr v-if="!modoVistaPrevia || (modoVistaPrevia && form.texto_retencion)">
                                            <td colspan="9">
                                                <span class="px-1 py-1 font-size-body text-bold">(*) Retenciones adicionales:</span>
                                            </td>
                                        </tr>
                                        <tr v-if="!modoVistaPrevia || (modoVistaPrevia && form.texto_retencion)">
                                            <td colspan="9" class="py-1 px-1 text-right font-size-body vertical-align-top">
                                                <div class="d-flex align-center" style="gap: 8px;">
                                                    <span v-if="modoVistaPrevia">{{ form.texto_retencion }}</span>
                                                    <v-text-field 
                                                        v-else
                                                        v-model="form.texto_retencion"
                                                        v-bind="generarCommonProps(texto_retencionErrors)"
                                                        class="d-block"
                                                        @change="$v.form.texto_retencion.$touch()"
                                                        @blur="$v.form.texto_retencion.$touch()"
                                                    />
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="py-1 px-1 text-right font-size-body text-bold" colspan="7">Monto total sin IVA USD</td>
                                            <td class="py-1 px-1 text-center font-size-body">${{ totalSinIva }}</td>
                                        </tr>
                                        <tr>
                                            <td class="py-1 px-1 text-right font-size-body text-bold" colspan="7">Monto total con IVA USD</td>
                                            <td class="py-1 px-1 text-center font-size-body">${{ montoTotal }}</td>
                                        </tr>

                                    </tbody>
                                </table>
    
                                <div class="border-1 font-size-body" style="border-top: 0">
                                    Esta Orden de Compra no es válida si no tiene abajo la firma en original de la máxima autoridad de 
                                    <span v-if="modoVistaPrevia">{{ form.alias_contratante }}</span>
                                    <v-text-field 
                                        v-else
                                        v-model="form.alias_contratante"
                                        v-bind="generarCommonProps(alias_contratanteErrors)"
                                        class="d-inline-block"
                                        style="width: 180px"
                                        @change="$v.form.alias_contratante.$touch()"
                                        @blur="$v.form.alias_contratante.$touch()"
                                    />
                                    .
                                    <br />
                                    Esta Orden de Compra está sujeta a todos los términos y condiciones indicadas en las páginas adjuntas, las cuales son parte integrante de la misma.
                                </div>
                                <div v-if="!modoVistaPrevia || (modoVistaPrevia && form.texto_retencion)">
                                    <span class="font-size-body">
                                        * El apartado "Retenciones adicionales" se ha dejado de forma visual y editable y no realizará ningún calculo aritmético ya que solo funcionara para facilitar la facturación de los acreditados hacia las instituciones
                                    </span>
                                </div>
    
                                <table class="mt-4 simple-table bordered-table">
                                    <thead class="d-none">
                                        <tr>
                                            <th style="width: 40%;">Parámetro</th>
                                            <th style="width: 60%">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="px-1 py-1 font-size-body text-bold">Autorizada por (Máxima autoridad): </td>
                                            <td class="px-1 py-1 font-size-body">
                                                <div class="d-flex align-center" style="height: 100%; gap: 8px;">
                                                    <span v-if="modoVistaPrevia">{{ form.autorizada_por }}</span>
                                                    <v-text-field 
                                                        v-else
                                                        placeholder="Nombre"
                                                        v-model="form.autorizada_por" 
                                                        v-bind="generarCommonProps(autorizada_porErrors)"
                                                        @change="$v.form.autorizada_por.$touch()"
                                                        @blur="$v.form.autorizada_por.$touch()"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="px-1 py-1 font-size-body vertical-align-top">Nombre y firma:</td>
                                            <td class="px-1 py-1 font-size-body">
                                                <template v-if="modoVistaPrevia">
                                                    <span class="d-block">{{ form.nombre_autorizador }}</span>
                                                    <div class="d-flex align-center" style="min-height: 100px;">
                                                        <img 
                                                            v-if="form.firma_autorizador" 
                                                            class="d-inline-block max-width-full"
                                                            :src="firma_autorizadorSrc" 
                                                            style="width: 40%;" 
                                                        />
                                                        <b v-if="!form.firma_autorizador && !modoVistaPrevia" class="d-inline-block" style="align-self: start;">
                                                            Firma no cargada.
                                                        </b>
                                                    </div>
                                                </template>
                                                <div v-else style="height: 100%;" class="py-1">
                                                    <v-text-field 
                                                        v-model="form.nombre_autorizador" 
                                                        v-bind="generarCommonProps(nombre_autorizadorErrors)"
                                                        class="d-block"
                                                        @change="$v.form.nombre_autorizador.$touch()"
                                                        @blur="$v.form.nombre_autorizador.$touch()"
                                                    />
                                                    <v-file-input
                                                        v-model="form.firma_autorizador"
                                                        v-bind="generarCommonProps([])"
                                                        label="Firma"
                                                        placeholder="Firma"
                                                        accept="image/*"
                                                        class="mt-4 "
                                                        light
                                                    />
                                                </div>
                                            </td>
                                        </tr> -->
                                        <!-- <tr>
                                            <td class="px-1 py-1 font-size-body text-bold">Aceptada por:</td>
                                            <td class="px-1 py-1 font-size-body">
                                                <div class="d-flex align-center" style="gap: 8px;">
                                                    <span v-if="modoVistaPrevia">{{ form.aceptado_por }}</span>
                                                    <v-text-field 
                                                        v-else
                                                        v-model="form.aceptado_por" 
                                                        v-bind="generarCommonProps(aceptado_porErrors)"
                                                        @change="$v.form.aceptado_por.$touch()"
                                                        @blur="$v.form.aceptado_por.$touch()"
                                                    />
                                                </div>
                                            </td>
                                        </tr> -->
                                        <tr>
                                            <td class="px-1 py-1 font-size-body vertical-align-top">Firma y sello:</td>
                                            <td class="px-1 py-1 font-size-body" style="height: 150px;">
                                                <template v-if="modoVistaPrevia">
                                                    <!-- <span class="d-block">{{ form.nombre_aceptante }}</span> -->
                                                    <!-- <div class="d-flex align-center" style="gap: 16px; min-height: 100px;">
                                                        <img 
                                                            v-if="form.firma_aceptante" 
                                                            class="d-inline-block max-width-full" 
                                                            :src="firma_aceptanteSrc" 
                                                            style="width: 40%;" 
                                                        />
                                                        <b v-if="!form.firma_aceptante" class="d-block">
                                                            Firma no cargada.
                                                        </b>
                                                        <img 
                                                            v-if="form.sello_aceptante" 
                                                            class="d-inline-block max-width-full" 
                                                            :src="sello_aceptanteSrc" 
                                                            style="width: 40%;" 
                                                        />
                                                        <b v-if="!form.sello_aceptante" class="d-block">
                                                            Sello no cargado.
                                                        </b>
                                                    </div> -->
                                                </template>
                                                <!-- <div v-else style="height: 100%;" class="py-1">
                                                    <v-text-field 
                                                        v-model="form.nombre_aceptante" 
                                                        v-bind="generarCommonProps(nombre_aceptanteErrors)"
                                                        @change="$v.form.nombre_aceptante.$touch()"
                                                        @blur="$v.form.nombre_aceptante.$touch()"
                                                        placeholder="Nombre"
                                                    />
                                                    <v-file-input
                                                        v-model="form.firma_aceptante"
                                                        v-bind="generarCommonProps([])"
                                                        label="Firma"
                                                        placeholder="Firma"
                                                        accept="image/*"
                                                        class="mt-4"
                                                        light
                                                    />
                                                    <v-file-input
                                                        v-model="form.sello_aceptante"
                                                        v-bind="generarCommonProps([])"
                                                        label="Sello"
                                                        placeholder="Sello"
                                                        accept="image/*"
                                                        class="mt-4"
                                                        light
                                                    />
                                                </div> -->
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="px-1 py-1 font-size-body">Fecha de aceptación:</td>
                                            <td class="px-1 py-1 font-size-body">
                                                <div class="d-flex align-center" style="gap: 8px;">
                                                    <span v-if="modoVistaPrevia">{{ fecha_aceptacion }}</span>
                                                    <v-menu v-else transition="scale-transition" min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div v-bind="attrs" v-on="on" style="width: 100%">
                                                                <v-text-field
                                                                    v-bind="generarCommonProps(fecha_aceptacionErrors)"
                                                                    placeholder="Fecha de aceptación"
                                                                    class="d-block"
                                                                    light 
                                                                    readonly
                                                                    :value="fecha_aceptacion"
                                                                    @change="$v.form.fecha_aceptacion.$touch()"
                                                                    @blur="$v.form.fecha_aceptacion.$touch()"
                                                                />
                                                            </div>
                                                        </template>
                                                        <div @click.stop>
                                                            <vc-date-picker 
                                                                v-model="form.fecha_aceptacion"
                                                                mode="dateTime"
                                                                locale="es"
                                                            />
                                                        </div>
                                                    </v-menu>
                                                </div>
                                            </td>
                                        </tr> -->
                                        <tr>
                                            <td class="px-1 py-1 font-size-body">Número de identificación del proveedor:</td>
                                            <td class="px-1 py-1 font-size-body">
                                                <div class="d-flex align-center" style="gap: 8px;">
                                                    <span>{{ informacionProveedor.numero_documento }}</span>
                                                    <!-- <span v-if="modoVistaPrevia">{{ form.numero_dui_responsable_contratista }}</span>
                                                    <v-text-field 
                                                        v-else
                                                        v-model="form.numero_dui_responsable_contratista"
                                                        v-bind="generarCommonProps(numero_dui_responsable_contratistaErrors)"
                                                        @change="$v.form.numero_dui_responsable_contratista.$touch()"
                                                        @blur="$v.form.numero_dui_responsable_contratista.$touch()"
                                                    /> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="px-1 py-1 font-size-body">Numero de NIT del Contratista:</td>
                                            <td class="px-1 py-1 font-size-body">
                                                <div class="d-flex align-center" style="gap: 8px;">
                                                    <span v-if="modoVistaPrevia"> {{ form.numero_nit_contratista }}</span>
                                                    <v-text-field 
                                                        v-else
                                                        v-model="form.numero_nit_contratista" 
                                                        v-bind="generarCommonProps(numero_nit_contratistaErrors)"
                                                        @change="$v.form.numero_nit_contratista.$touch()"
                                                        @blur="$v.form.numero_nit_contratista.$touch()"
                                                    />
                                                </div>
                                            </td>
                                        </tr> -->
                                        <tr>
                                            <td class="px-1 py-1 font-size-body" style="line-height: 22px;" colspan="2">
                                                <span class="px-1 py-1 font-size-body text-bold">Comentario:</span>
                                                <div v-if="modoVistaPrevia" style="min-height: 32px;">{{ form.vigencia  }}</div>
                                                <v-textarea
                                                    v-else
                                                    v-model="form.vigencia" 
                                                    v-bind="generarCommonProps(vigenciaErrors)"
                                                    class="d-block"
                                                    rows="3"
                                                    no-resize
                                                    @change="$v.form.vigencia.$touch()"
                                                    @blur="$v.form.vigencia.$touch()"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Condiciones 
                                    :esCMCombustible="esCMCombustible"
                                    :modo-vista-previa="modoVistaPrevia"
                                    :direccion-facturacion="form.direccion_facturacion"

                                    :factura.sync="form.factura"
                                    :validador-factura="$v.form.factura.$touch"
                                    :factura-errors="facturaErrors"

                                    :alias-contratante.sync="form.alias_contratante"
                                    :validador-alias-contratante="$v.form.alias_contratante.$touch"
                                    :alias-contratante-errors="alias_contratanteErrors"

                                    :abreviacion-contratante.sync="form.abreviacion_contratante"
                                    :validador-abreviacion-contratante="$v.form.abreviacion_contratante.$touch"
                                    :abreviacion-contratante-errors="abreviacion_contratanteErrors"

                                    :nombre-delegado.sync="form.nombre_delegado"

                                    :dui-delegado.sync="form.dui_delegado"
                                    :validador-dui-delegado="$v.form.dui_delegado.$touch"
                                    :dui-delegado-errors="dui_delegadoErrors"

                                    :telefono-administrador="solicitud?.telefono"

                                    :condicion-personalizada.sync="form.condicion_personalizada"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { required, maxLength, helpers, decimal } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation.js';
import Condiciones from './Condiciones.vue';
import { mapState } from 'vuex';
import { isNil } from 'lodash';
// import moment from 'moment';
import { joinList } from '@/utils/text';
import TiempoEntrega from '@/views/CMOrdenCompra/components/TiempoEntrega.vue';

// const duiRegex = helpers.regex("regex", /^\d{8}-\d{1}$/);
// const nitRegex = helpers.regex("regex", /^(\d{8}-\d{1})|(\d{4}-\d{6}-\d{3}-\d)$/);

const estadoInicialForm = {
    direccion_facturacion: null,
    texto_retencion: null,
    // Autorizador
    autorizada_por: null,
    // nombre_autorizador: null,
    firma_autorizador: null,
    // Aceptante
    // aceptado_por: null,
    // nombre_aceptante: null,
    firma_aceptante: null,
    sello_aceptante: null,
    // fecha_aceptacion: null,
    // numero_dui_responsable_contratista: null,
    // numero_nit_contratista: null,
    vigencia: null,
    // Condiciones
    factura:null,
    contratante: null,
    abreviacion_contratante: null,
    alias_contratante: null,
    nombre_delegado: null,
    dui_delegado: null,
    condicion_personalizada: null,
    // Correlativo de la orden de compra para la institucion
    correlativo_orden_institucion: null,
    // Factura de emicion
    // factura_emitida:null,
};

export default {
    name: 'ModalGenerarOrdenCompra',
    components: { Condiciones, TiempoEntrega },
    emits: ['on-visibility-change', 'on-save'],
    validations() {
        return ({
            form: {
                direccion_facturacion: { required, maxLength: maxLength(350) },
                texto_retencion: { maxLength: maxLength(250) },
                // Autorizador
                autorizada_por: { required, maxLength: maxLength(250) },
                // nombre_autorizador: { required, maxLength: maxLength(250) },
                // Aceptante
                // aceptado_por: { required, maxLength: maxLength(250) },
                // nombre_aceptante:{ required, maxLength: maxLength(250) },
                // fecha_aceptacion: { required },
                // numero_dui_responsable_contratista: { required, regex: duiRegex },
                // numero_nit_contratista: { required, regex: nitRegex },
                vigencia: { maxLength: maxLength(500) },
                // Condiciones
                // contratante: { required },
                factura: { required },
                alias_contratante: { required },
                abreviacion_contratante: { required },
                dui_delegado: { required },
                //Nuevo campo para que la institucion coloque un correlativo.
                correlativo_orden_institucion: { required },

            },
        });
    },
    props: {
        isOpen: { type: Boolean },
        solicitud: { type: Object },
        contactoUCP: { type: Object },
        productos: { type: Array, default: [], },
        proveedor: { type: Object },
        total: { type: [String, Number] },
        ordenCompra: { type: Object },
        valoresPrevios: { type: Object },
    },
    data: () =>({
        internalOpen: false,
        modoVistaPrevia: false,
        form: { ...estadoInicialForm },
    }),
    computed: {
        ...mapState(["serverTime"]),
        // Errores en data
        direccion_facturacionErrors() {
            return new Validator(this.$v.form.direccion_facturacion).detect().getResult();
        },
        correlativoOrdenInstitucion() {
            return new Validator(this.$v.form.correlativo_orden_institucion).detect().getResult();
        },
        texto_retencionErrors() {
            return new Validator(this.$v.form.texto_retencion).detect().getResult();
        },
        autorizada_porErrors() {
            return new Validator(this.$v.form.autorizada_por).detect().getResult();
        },
        // nombre_autorizadorErrors() {
        //     return new Validator(this.$v.form.nombre_autorizador).detect().getResult();
        // },
        // aceptado_porErrors() {
        //     return new Validator(this.$v.form.aceptado_por).detect().getResult();
        // },
        // nombre_aceptanteErrors() {
        //     return new Validator(this.$v.form.nombre_aceptante).detect().getResult();
        // },
        // fecha_aceptacionErrors() {
        //     return new Validator(this.$v.form.fecha_aceptacion).detect().getResult();
        // },
        // numero_dui_responsable_contratistaErrors() {
        //     return new Validator(this.$v.form.numero_dui_responsable_contratista).required().regex('Formato debe ser del tipo ########-#').getResult();
        // },
        // numero_nit_contratistaErrors() {
        //     return new Validator(this.$v.form.numero_nit_contratista)
        //         .required()
        //         .regex('Formato debe ser del tipo ########-# o ####-######-###-#')
        //         .getResult();
        // },
        vigenciaErrors() {
            return new Validator(this.$v.form.vigencia).detect().getResult();
        },
        // contratanteErrors() {
        //     return new Validator(this.$v.form.contratante).detect().getResult();
        // },
        facturaErrors() {
            return new Validator(this.$v.form.factura).detect().getResult();
        },
        // facturaEmitidaErrors() {
        //     return new Validator(this.$v.form.factura_emitida).detect().getResult();
        // },
        alias_contratanteErrors() {
            return new Validator(this.$v.form.alias_contratante).detect().getResult();
        },
        abreviacion_contratanteErrors() {
            return new Validator(this.$v.form.abreviacion_contratante).detect().getResult();
        },
        dui_delegadoErrors() {
            return new Validator(this.$v.form.dui_delegado).detect().getResult();
        },
        // UI
        fechaHoy() {
            return this.serverTime?.format("DD/MM/YYYY HH:mm:ss");
        },
        firma_autorizadorSrc() {
            if (!this.form.firma_autorizador) return null;

            return window.URL.createObjectURL(new Blob([this.form.firma_autorizador]));
        },
        firma_aceptanteSrc() {
            if (!this.form.firma_aceptante) return null;

            return window.URL.createObjectURL(new Blob([this.form.firma_aceptante]));
        },
        sello_aceptanteSrc() {
            if (!this.form.sello_aceptante) return null;

            return window.URL.createObjectURL(new Blob([this.form.sello_aceptante]));
        },
        // fecha_aceptacion() {
        //     const fecha = this.form.fecha_aceptacion;

        //     return fecha 
        //         ? moment(fecha).format("DD/MM/YYYY HH:mm:ss")
        //         : null;
        // },
        informacionProveedor() {
            if (!this.proveedor) return '';

            return this.proveedor;
        },
        institucion() {
            if (!this.solicitud) return '';
            return this.solicitud.Institucion.nombre;
        },
        montoTotal() {
            if (!this.total) return 0;
            return Number(this.total).toFixed(2);
        },
        totalSinIva(){
            if (!this.total) return 0;

            const totalSinIva = this.total / 1.13;
            return Number(totalSinIva).toFixed(2);
        },
        esCMCombustible() {
            return this.productos.some((p) => {
                const { monto, cifra, vigencia, cantidad } = p.subGrupo;
                return (cantidad || monto) && vigencia && cifra;
            });
        }
    },
    methods: {
        generarCommonProps(errores) {
            return ({ outlined: true, dense: true, 'error-messages': errores, 'hide-details': errores.length === 0 });
        },
        limpiarFormulario() {
            this.$v.form.$reset();
            this.form = { ...estadoInicialForm };
        },
        cerrarModal() {
            this.internalOpen = false;
            this.limpiarFormulario();
        },
        generarOrdenCompra() {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                this.modoVistaPrevia = false;
                this.pushAppMessage({ type: 'info', message: 'Aún hay campos pendientes por completar.' });
                return;
            };
        
            this.$emit('on-save', { ...this.form });
            this.cerrarModal();
        },
        manejarCambioEnScroll(event) {
            const scrollPosition = event.target.scrollLeft;
            this.$refs.plantilla.scroll({ left: scrollPosition });
        },
        formatearPersona(persona) {
            if (!persona) return "";

            const componentes = [
                persona.primer_nombre,
                persona.segundo_nombre,
                persona.tercer_nombre,
                persona.primer_apellido,
                persona.segundo_apellido,
                persona.apellido_casada,
            ];

            return joinList(componentes, " ");
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;
            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
        valoresPrevios: {
            handler(value) {
                if (!value) return;
                this.form = { ...estadoInicialForm, ...value };
                this.form.alias_contratante = this.form.alias_contratante ? this.form.alias_contratante : this.solicitud.Institucion.nombre
                this.form.nombre_delegado = this.form.nombre_delegado ? this.form.nombre_delegado : this.formatearPersona(this.solicitud?.us_c?.Empleado.Persona)
                this.form.dui_delegado = this.form.dui_delegado ? this.form.dui_delegado : this.solicitud?.us_c?.Empleado.Persona.DocumentoPersonas[0].numero_documento    
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.plantilla {
    max-width: 100%; 
    overflow-x: auto;
    position: relative;
}

.top-scroller {
    max-width: 100%; 
    overflow-x: auto;
    position: sticky;
    top: 80px;
    z-index: 1;
}

:deep(*) {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.v-input--selection-controls__ripple) {
    left: -6px !important;
    top: calc(50% - 18px) !important;
}

:deep(.v-text-field__details) {
    margin-bottom: 0 !important;
}

table thead{
    display: table-header-group;
    break-inside: auto;
}

table tr th,
table tr td {
    page-break-inside: avoid;
}

body {
    background-color: #FFFFFF;
    padding: 32px;
}

/** Contenido principal **/
.table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: solid 1px #000000;
}

.table thead {
    background-color: #44546A;
    color: #FFFFFF;
}

.table thead tr th {
    text-align: left;
    font-size: 12px;
}

.table tbody tr td {
    font-size: 12px;
}

.simple-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: solid 1px #000000;
}

.simple-table thead {
    background-color: #44546A;
    color: #FFFFFF;
}

.simple-table thead tr th
.simple-table tbody tr td {
    text-align: center;
}

.border-1 {
    display: block;
    padding: 4px;
    border: solid 1px #000000;
}

.bordered-table {
    width: 100%;
    border-collapse: collapse;
}

.bordered-table thead tr th,
.bordered-table tbody tr td {
    border: 1px solid #000000;
}

/** Estilos en comun **/
.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.font-size-body {
    font-size: 12px;
}

.text-bold {
    font-weight: bold;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize-first-letter::first-letter {
    text-transform: uppercase;
}

.text-underlined {
    text-decoration: underline;
}

.zero-line-height {
    line-height: 0;
}

.vertical-align-top {
    vertical-align: top;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-none {
    display: none;
}

.max-width-full {
    max-width: 100%;
}

.img-cover {
    object-fit: cover;
}

/** Utilidades flex **/
.d-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

/** Margenes **/
.mt-2 {
    margin-top: 8px;
}

.mt-4 {
    margin-top: 16px;
}

.mt-8 {
    margin-top: 32px;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-8 {
    margin-bottom: 32px;
}

.mb-2 {
    margin-bottom: 8px;
}

.mb-4 {
    margin-bottom: 16px;
}

.mr-1 {
    margin-right: 4px;
}

.mr-2 {
    margin-right: 8px;
}

/** Padding **/
.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.py-0 {
    padding-bottom: 0;
    padding-top: 0;
}

.px-1 {
    padding-left: 4px;
    padding-right: 4px;
}

.py-1 {
    padding-bottom: 4px;
    padding-top: 4px;
}

.px-2 {
    padding-left: 8px;
    padding-right: 8px;
}

.py-2 {
    padding-bottom: 8px;
    padding-top: 8px;
}

.px-4 {
    padding-left: 16px;
    padding-right: 16px;
}

.py-4 {
    padding-bottom: 16px;
    padding-top: 16px;
}
</style>
