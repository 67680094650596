<template>
  <v-card width="600" style="overflow: hidden">
    <section>
      <v-row no-gutters class="mt-6 mb-4">
        <v-col class="ml-6 mr-6" cols="5" md="5">
          <v-btn
            max-width="90%"
            outlined
            text
            block
            class="custom-read-btn"
            :disabled="noNotifications"
            @click="markAllAsRead"
          >
            Marcar como leídas
            <v-icon size="18" class="ml-4"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </v-btn>
        </v-col>
        <v-col class="ml-6 mr-6" cols="5" md="5">
          <v-btn
            max-width="90%"
            outlined
            text
            block
            class="custom-read-btn"
            :disabled="noNotifications"
            @click="deleteAll"
          >
            Eliminar todas
            <v-icon size="18" class="ml-4">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="notificationsContainer"
        v-if="notificaciones_list?.length > 0"
        id="lastItem"
        no-gutters
      >
        <v-col cols="12">
          <v-list two-line>
            <v-list-item-group>
              <template v-for="(notificacion, id) in notificaciones_ordenadas">
                <v-list-item :class="!notificacion.read ? 'blue-grey lighten-5' : ''" :key="id">
                  <v-list-item-content @click="readNotification(notificacion)">
                    <v-list-item-title class="my-4 text-wrap">{{
                      notificacion.detalle?.contenido
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <span>
                        {{
                          moment(notificacion.created_at_text).format("HH:mm:ss") || ""
                        }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                    <v-icon @click="deleteNotification(notificacion)">
                    mdi-close-circle-outline</v-icon>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </section>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapMutations, mapState } from "vuex";
export default {
  name: "NotificacionesComponent",
  data: () => ({
    currentPage: 1,
    loadingMore: false,
  }),
  computed: {
    ...mapState(["selectedUnidad", "notificaciones_list"]),
    noNotifications() {
      return this.notificaciones_list.length === 0;
    },
    notificaciones_ordenadas(){
      return this.notificaciones_list.sort((a, b) => new Date(b.created_at_text) - new Date(a.created_at_text));
    }
  },
  methods: {
    ...mapMutations(["setNotificacionesPendientes", "setNotificaciones"]),
    async getNotificaciones() {
      const { data } = await this.services.Notificaciones.getNotificaciones();
      this.setNotificaciones(data);
    },
    async readNotification(notificacion) {
      await this.services.Notificaciones.markAsRead(notificacion.id);
      await this.getNotificaciones();

      const {
        detalle: { action },
      } = notificacion;

      if (action?.redirect_to) {
        this.$router.replace({
          name: action?.redirect_to?.name,
          params: action?.redirect_to?.params,
        });
      }
    },
    async deleteNotification(notificacion) {
      await this.services.Notificaciones.deleteNotification(notificacion?.id);
      await this.getNotificaciones();
    },
    async markAllAsRead() {
      await this.services.Notificaciones.readAllNotifications();
      await this.getNotificaciones();
    },
    async deleteAll() {
      await this.services.Notificaciones.deleteAllNotifications();
      await this.getNotificaciones();
    },
  },
};
</script>
<style scoped>
.custom-read-btn {
  text-transform: initial;
  font-size: 16px;
  letter-spacing: initial !important;
}

.notificationsContainer {
  overflow-y: scroll;
  max-height: 410px;
}

.notificationsContainer::-webkit-scrollbar {
  width: 7px !important;
  background-color: #ffffff;
}
.notificationsContainer::-webkit-scrollbar-thumb {
  background: #757575;
  border-radius: 20px;
}
.theme--light.v-list-item--active:before {
  opacity: 0 !important;
}
</style>
